/* eslint-disable jsx-a11y/media-has-caption */
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import type { HeadFC } from "gatsby";
import type { QueryReturnHead } from "@typing/types";

// import BGInvest from "@images/bg-invest.png";
import People1 from "@images/people-1.png";
import People2 from "@images/people-2.png";
import People3 from "@images/people-3.png";
import HeroVideo from "@videos/hero.mp4";

import IconProtection from "@icons/icon-protection.svg";
import IconSecurity from "@icons/icon-privacy.svg";
import IconFiability from "@icons/icon-fiability.svg";
import IconStocks from "@icons/icon-stocks.svg";
import IconCash from "@icons/icon-cash.svg";
import IconTools from "@icons/icon-tools.svg";

import "@stylesPages/Home.scss";

import { ContactForm } from "@containers";
import { Footer, Header, SEO } from "@components";

const IndexPage = () => {
  const { t } = useTranslation("home");

  return (
    <>
      <Header absolute />
      <section className="hero">
        <video
          className="hero__video"
          loop
          muted
          preload="auto"
          autoPlay
          playsInline
        >
          <source src={HeroVideo} type="video/mp4" />
        </video>
        <div className="hero__container">
          <h1 className="hero__title">{t("hero-title")}</h1>
          <h2 className="hero__subtitle">{t("hero-subtitle")}</h2>
          <Link to="/#contact" className="hero__button">
            {t("about-button")}
          </Link>
        </div>
      </section>
      <section className="graph">
        <h1 className="graph__title">
          <span>{t("graph-title")}</span>
        </h1>
        <h2 className="graph__subtitle">
          <span>{t("graph-subtitle")}</span>
        </h2>
      </section>
      <section className="about">
        <div className="about__container">
          <div className="about__flex">
            <Link to="/#contact" className="about__button">
              {t("about-button")}
            </Link>
            <div className="about__content">
              <h2 className="about__title">{t("about-title")}</h2>
              <p className="about__text">{t("about-text-one")}</p>
              <p className="about__text about__text--bold">{t("about-text-two")}</p>
            </div>
          </div>
          <h2 className="about__title about__title--center">{t("about-title-two")}</h2>
          <p className="about__text about__text--center">{t("about-text-three")}</p>
          <div className="about__grid">
            <article className="about__card">
              <img src={IconProtection} alt="" className="about__card-icon" />
              <h1 className="about__card-title">{t("about-card-title-one")}</h1>
              <p className="about__card-text">{t("about-card-text-one")}</p>
            </article>
            <article className="about__card">
              <img src={IconSecurity} alt="" className="about__card-icon" />
              <h1 className="about__card-title">{t("about-card-title-two")}</h1>
              <p className="about__card-text">{t("about-card-text-two")}</p>
            </article>
            <article className="about__card">
              <img src={IconFiability} alt="" className="about__card-icon" />
              <h1 className="about__card-title">{t("about-card-title-three")}</h1>
              <p className="about__card-text">{t("about-card-text-three")}</p>
            </article>
            <article className="about__card">
              <img src={IconStocks} alt="" className="about__card-icon" />
              <h1 className="about__card-title">{t("about-card-title-four")}</h1>
              <p className="about__card-text">{t("about-card-text-four")}</p>
            </article>
            <article className="about__card">
              <img src={IconCash} alt="" className="about__card-icon" />
              <h1 className="about__card-title">{t("about-card-title-five")}</h1>
              <p className="about__card-text">{t("about-card-text-five")}</p>
            </article>
            <article className="about__card">
              <img src={IconTools} alt="" className="about__card-icon" />
              <h1 className="about__card-title">{t("about-card-title-six")}</h1>
              <p className="about__card-text">{t("about-card-text-six")}</p>
            </article>
          </div>
        </div>
      </section>
      <section className="reviews">
        <div className="reviews__container">
          <p className="reviews__text">{t("reviews-text-two")}</p>
          <h1 className="reviews__title">{t("reviews-title")}</h1>
          <p className="reviews__text">{t("reviews-text")}</p>
          <div className="reviews__cards">
            <article className="reviews__card">
              <p className="reviews__card-text">{t("review-text-one")}</p>
              <div className="reviews__card-box">
                <img src={People1} alt="" className="reviews__card-person" />
                <p className="reviews__card-title">{t("review-title-one")}</p>
              </div>
            </article>
            <article className="reviews__card">
              <p className="reviews__card-text">{t("review-text-two")}</p>
              <div className="reviews__card-box">
                <img src={People2} alt="" className="reviews__card-person" />
                <p className="reviews__card-title">{t("review-title-two")}</p>
              </div>
            </article>
            <article className="reviews__card">
              <p className="reviews__card-text">{t("review-text-three")}</p>
              <div className="reviews__card-box">
                <img src={People3} alt="" className="reviews__card-person" />
                <p className="reviews__card-title">{t("review-title-three")}</p>
              </div>
            </article>
          </div>
        </div>
      </section>
      <section className="contact">
        <div className="contact__container">
          <h1 className="contact__title">{t("contact-title")}</h1>
          <p className="contact__text">{t("contact-text")}</p>
          <Link to="/#contact" className="contact__button">
            {t("contact-button")}
          </Link>
        </div>
      </section>
      <ContactForm />
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head: HeadFC<QueryReturnHead> = ({ data: { locales } }) => {
  const home = locales.edges.find(({ node }) => (node.ns === "home"));

  const { "title-seo": titleSeo } = home ? JSON.parse(home.node.data) : {
    "title-seo": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
