import { Formik, Form } from "formik";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import swal from "sweetalert";
import { ContactSchema, DEFAULT_CONTACT_VALUES } from "@schemas";
import { sendContactEmail } from "@services";
import { useGetCountry } from "@hooks";

import { Checkbox, Input, LoaderPage, TextArea } from "@components";

export default function ContactForm() {
  const { t } = useTranslation("formik");
  const { country: { phoneCode } } = useGetCountry();

  const formTranslations = {
    required: t("required"),
    requiredTerms: t("required-terms")
  };

  const handleSubmit = async (values, actions) => {
    try {
      actions.setSubmitting(true);

      const { error } = await sendContactEmail({ ...values, phoneCode });
      if (error) throw new Error(error);

      actions.resetForm(DEFAULT_CONTACT_VALUES);
      swal({
        title: t("success", { ns: "translation" }),
        text: t("success-text", { ns: "translation" }),
        icon: "success"
      });
    } catch (error) {
      swal("Error!", t(error.message, { ns: "errors" }), "error");
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={DEFAULT_CONTACT_VALUES}
      validationSchema={ContactSchema(formTranslations)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className="contact-page__form" id="contact">
          {isSubmitting ? <LoaderPage /> : null}
          <h1 className="contact-page__title">{t("title", { ns: "contact" })}</h1>
          <p className="contact-page__text">{t("text", { ns: "contact" })}</p>
          <div className="contact-page__form-content">
            <div className="contact-page__form-grid">
              <Input
                label={t("name")}
                name="name"
                type="text"
                placeholder={t("name-holder")}
              />
              <Input
                label={t("email")}
                name="email"
                type="email"
                placeholder={t("email-holder")}
              />
              <Input
                label={t("phone")}
                name="phone"
                type="text"
                placeholder={t("phone-holder")}
              />
            </div>
            <TextArea
              label={t("details")}
              name="message"
              type="text"
              placeholder={t("details-holder")}
              rows={3}
            />
            <div className="contact-page__form-box">
              <Checkbox
                label={t("terms")}
                name="terms"
                checked={values.terms}
              >
                <Link to="/legals" className="contact-page__form-link">
                  {t("title", { ns: "terms" })}
                </Link>
                {t("and")}
                <Link to="/legals?type=privacy" className="contact-page__form-link">
                  {t("title", { ns: "privacy" })}
                </Link>
              </Checkbox>
              <button
                type="submit"
                className="contact-page__form-button"
                disabled={isSubmitting}
              >
                {t("contact-submit")}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
