import config from "@config";

export const sendContactEmail = async (values) => {
  const request = await fetch(`${config.api}/send-email-inverlion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(values)
  });
  const response = await request.json();

  return response;
};

export const other = {};
